import TablePage from '../../../../components/table_page';
import TerminalScanList from './form/terminal_scan_list.vue';
import CustomerScanList from './form/customer_scan_list.vue';

export default {
  name: 'dealer_related_information_report',
  extends: TablePage,
  data() {
    return {};
  },
  components: {
    TerminalScanList,
    CustomerScanList,
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'terIn') {
        this.formName = 'TerminalScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端入库数量';
        this.openFull();
      }
      if (column.property === 'conIn') {
        this.formName = 'CustomerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '消费者开瓶数量';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      if (val.code === 'terminal_inventory_quantity') {
        this.formName = 'TerminalScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端入库数量';
        this.openFull();
      }
      if (val.code === 'consumer_opened_bottle_quantity') {
        this.formName = 'CustomerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '消费者开瓶数量';
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('terminal_scan_list');
  },

};
